<template>
  <!-- 消息通知 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <div>
              <el-badge :value="msgTotal" class="item" v-if="msgTotal != 0">
                <span class="SubHead">消息通知</span>
              </el-badge>

              <span class="SubHead" v-else>消息通知</span>
            </div>
            <div>
               <el-button size="mini" type="primary" @click="readAll" :disabled="msgTotal == 0 ? true : false"
              >全部已读</el-button
            >
            <router-link to="/subMsg">
              <span class="brandColor subMsg">通知管理</span>
            </router-link>
            </div>
          </div>
        </template>

        <el-table
          ref="officialInfoRef"
          :data="msgDetail"
          style="width: 100%"
          :header-cell-style="{
            background: 'var(--el-table-row-hover-background-color)'
          }"
          :row-style="{
            cursor: 'pointer'
          }"
          :show-header="false"
          :row-key="getRowId"
          @row-click="rowClick"
          @expand-change="changeTips"
        >
          <el-table-column label="消息标题" prop="">
            <template #default="{ row }">
              <i class="iconfont icon-yldian" :class="row.is_read == 0 ? 'new ' : ' old'"></i>
              {{ row.title }}
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="time" align="right" />
          <el-table-column type="expand">
            <template #default="{ row }">
              <p style="padding-left: 15px" v-html="row.content"></p>
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination
          v-model:currentPage="queryInfo.page"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          :pager-count="5"
          :background="true"
        >
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Message',
  data() {
    return {
      userInfo: '',
      isExpand: true,
      msgDetail: [],
      // 获取消息列表的参数对象
      queryInfo: {
        query: '',
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      total: 0,
      msgTotal: 0
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.getMsgInfo()
    this.getMsgTotal()
  },
  methods: {
    // 获取消息列表
    async getMsgInfo() {
      const { data: res } = await this.$http.post('user/message_list', {
        page: this.queryInfo.page
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.msgDetail = res.data
      this.total = res.total
    },
    getRowId(row) {
      return row.id
    },
    // 点击行触发展开行
    async rowClick(row) {
      this.isExpand = false
      this.$refs.officialInfoRef.toggleRowExpansion(row)
      if (row.is_read == 0) {
        const { data: res } = await this.$http.post('user/set_message', {
          type: 3,
          id: row.id
        })
        row.is_read = 1
        this.msgTotal = res.total
        this.isExpand = true
        this.$bus.emit('getMsgTotal')
      }
    },
    // 点击展开图标触发展开行
    async changeTips(row) {
      if (row.is_read == 0 && this.isExpand !== false) {
        const { data: res } = await this.$http.post('user/set_message', {
          type: 3,
          id: row.id
        })
        row.is_read = 1
        this.msgTotal = res.total
        this.$bus.emit('getMsgTotal')
      }
    },
    async getMsgTotal() {
      const { data: res } = await this.$http.post('user/message', {
        token: this.userInfo.token
      })
      this.msgTotal = res.num
    },
    async readAll() {
      const { data: res } = await this.$http.post('user/set_message', {
        type: 1
      })
      window.location.reload()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPages) {
      this.queryInfo.page = newPages
      this.getMsgInfo()
    }
  }
}
</script>

<style lang="less" scoped>
a {
  color: #2b82ff;
  text-decoration: none;
}
i.icon-dian.new {
  color: #2b82ff;
}
i.icon-dian.old {
  color: #ccc;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subMsg {
  font-size: 14px;
  color: #2b82ff;
  margin-left: 10px;
}
</style>
