<template>
  <el-row class="firstRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">邀请有奖</span>
          </div>
        </template>
        <div class="title">邀请步骤</div>
        <el-row class="stepRow">
          <el-col :span="6">
            <div class="stepBox">
              <div class="left">
                <i class="iconfont icon-yllianjie"></i>
                <div class="introText">
                  1.分享链接/海报
                </div>
              </div>
              <i class="iconfont icon-ylxiayibu"></i>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="stepBox">
              <div class="left">
                <i class="iconfont icon-ylzhuce"></i>
                <div class="introText">
                  2.好友点击注册
                </div>
              </div>
              <i class="iconfont icon-ylxiayibu"></i>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="stepBox">
              <div class="left">
                <i class="iconfont icon-ylrenzheng"></i>
                <div class="introText">
                  3.好友完成企业认证
                </div>
              </div>
              <i class="iconfont icon-ylxiayibu"></i>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="stepBox">
              <div class="left">
                <i class="iconfont icon-yljifen"></i>
                <div class="introText">
                  4.获得积分
                </div>
              </div>
              <i></i>
            </div>
          </el-col>
        </el-row>
        <div class="title">立即邀请</div>
        <el-row :gutter="40" style="margin-bottom: 10px;">
          <el-col :span="12">
            <el-input v-model="inviteLink" disabled>
              <template #append>
                <div style="padding:0 40px;">
                  <el-button type="text" style="color: #2b82ff;" @click="copyLink">复制链接</el-button>
                </div>
              </template>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="createPoster">生成分享海报</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">邀约好友列表</span>
          </div>
        </template>
        <el-table
          ref="pointRef"
          :data="inviteList"
          :row-key="getInviteId"
          class="pointTable"
          style="width: 100%"
          :header-cell-style="{
            background: 'var(--el-table-row-hover-background-color)'
          }"
        >
          <el-table-column property="name" label="用户名" />
          <el-table-column property="business_license" label="营业执照" />
          <el-table-column property="phone" label="手机号" />
          <el-table-column property="" label="是否登录">
            <template #default="{row}">
              <el-tag type="success" v-if="row.is_login == 1">已登录</el-tag>
              <el-tag type="danger" v-else>未登录</el-tag>
            </template>
          </el-table-column>
          <el-table-column property="is_attest" label="是否认证">
            <template #default="{row}">
              <el-tag type="success" v-if="row.is_attest == 1">已认证</el-tag>
              <el-tag type="danger" v-else>未认证</el-tag>
            </template>
          </el-table-column>
          <el-table-column property="integral" label="积分">
            <template #default="{row}">
              <span style="color:#2b82ff;">+ {{ row.integral }}</span>
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>
        <el-row>
          <!-- 分页器 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            :page-sizes="[5, 10]"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

  <el-dialog v-model="posterVisible" title="" width="683px">
    <div class="posterBox">
      <img :src="qrCode" class="inviteImg" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="posterVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="savePoster">
          保存海报
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'Invite',
  data() {
    return {
      inviteLink: '',
      qrCode: '',
      inviteList: [],
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      posterVisible: false
    }
  },
  mounted() {
    this.getInviteData()
  },
  methods: {
    async getInviteData() {
      const { data: res } = await this.$http.post('/user/invite')
      this.inviteLink = res.url
      this.qrCode = res.img
      this.inviteList = res.data
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getInviteData()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getInviteData()
    },
    copyLink() {
      this.$copyText(`${this.inviteLink}`).then(
        e => {
          this.$message.success('复制成功！')
        },
        e => {
          this.$message.error('复制失败！')
        }
      )
    },
    getInviteId(invite) {
      return invite.id
    },
    createPoster() {
      this.posterVisible = true
    },
    savePoster() {
      window.open(this.qrCode)
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-input-group__append) {
  background-color: #f5f7fa80;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstRow {
  margin-bottom: 16px;
}
.firstRow .title {
  font-size: 14px;
  color: #262626;
  margin-bottom: 30px;
}
.stepRow {
  margin-bottom: 48px;
}
.stepBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.stepBox .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stepBox .left .iconfont {
  display: block;
  font-size: 60px;
  color: #2b82ff;
  margin-bottom: 16px;
}
.stepBox .icon-ylxiayibu {
  font-size: 20px;
  color: #2b82ff;
  margin-top: 20px;
}
.stepBox .introText {
  font-size: 14px;
  color: #595959;
}
.posterBox {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f6ff;
  text-align: center;
  padding: 40px 0;
  box-sizing: border-box;
}
.posterBox img.inviteImg {
  width: 323px;
  height: 624px;
}
.qrCode {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 378px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
