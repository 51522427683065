<template>
  <el-dialog
      v-model="editEmailVisible"
      @close="editEmailClose"
      :close-on-click-modal="false"
      title="修改邮箱"
      width="600px"
      custom-class="editEmailDialog"
  >
    <!-- <p>为了你的账户安全，请验证身份。</p> -->
    <el-steps :active="stepActive" finish-status="success" align-center>
      <el-step title="验证手机号"/>
      <el-step title="验证新邮箱"/>
    </el-steps>

    <!-- 验证手机号 -->
    <el-form ref="phoneFormRef" :rules="phoneFormRules" :model="phoneForm" class="phoneForm" v-if="stepActive == 0">
      <el-form-item prop="phone">
        <el-input v-model="phoneForm.phone" placeholder="请输入新的手机号" size="small" disabled></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="phoneForm.code" placeholder="请输入验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align:right;">
            <el-button ref="editPhoneRef" size="small" :disabled="!rightPhone" @click="getCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item style="text-align:right;">
        <el-button type="primary" size="small" @click="nextStep">下一步</el-button>
      </el-form-item>
    </el-form>

    <!-- 验证邮箱号 -->
    <el-form ref="emailFormRef" :rules="emailFormRules" :model="emailForm" class="emailForm" v-if="stepActive == 1">
      <el-form-item prop="email">
        <el-input v-model="emailForm.email" placeholder="请输入新的邮箱号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="emailForm.code" placeholder="请输入验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align:right;">
            <el-button ref="editPhoneRef" size="small" :disabled="!rightEmail" @click="getCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item style="text-align:right;">
        <el-button type="primary" size="small" @click="doneStep">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'EditEmailDialog',
  data() {
    return {
      userInfo: null,
      editEmailVisible: false,
      stepActive: 0,
      countDownTime: 0,
      phoneForm: {
        phone: '',
        code: ''
      },
      phoneFormRules: {
        phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
        code: [{required: true, message: '请输入手机验证码', trigger: 'blur'}]
      },
      emailForm: {
        email: '',
        code: ''
      },
      emailFormRules: {
        email: [
          {required: true, message: '请输入邮箱号码', trigger: 'blur'},
          {
            pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
            message: '请输入正确格式的邮箱号码',
            trigger: 'change'
          }
        ],
        code: [{required: true, message: '请输入正确的邮箱验证码', trigger: 'blur'}]
      }
    }
  },
  computed: {
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(this.phoneForm.phone)
    },
    rightEmail() {
      return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.emailForm.email)
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.$bus.on('openEditEmail', this.openEditEmail)
    if (this.userInfo != null) {
      this.phoneForm.phone = this.userInfo.phone
    }
  },
  methods: {
    openEditEmail() {
      this.editEmailVisible = true
    },
    async getCode() {
      // 如果当前没有计时
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        this.editClock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(this.editClock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)

        // 发送axios请求
        if (this.stepActive == 0) {
          this.$http.post('login/login', {
            phone: this.userInfo.phone,
            type: 'code'
          })
        } else if (this.stepActive == 1) {
          const {data: res} = await this.$http.post('user/edit_email', {
            email: this.emailForm.email,
            type: 1
          })
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          if (res.code == 200) {
            this.$message.success(`${res.msg}`)
          }
        }
      }
    },
    editEmailClose() {
      this.stepActive = 0
      clearInterval(this.editClock)
      this.countDownTime = 0
      if (this.$refs.phoneFormRef) this.$refs.phoneFormRef.resetFields()
      // this.editEmailVisible = false
    },
    nextStep() {
      this.$refs.phoneFormRef.validate(async valid => {
        if (!valid) return
        let {data: res} = await this.$http.post('user/edit_phone', {
          type: 1,
          phoneCode: this.phoneForm.code
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          return this.$router.push('/login')
        }
        
        this.stepActive = 1
        clearInterval(this.editClock)
        this.countDownTime = 0
        this.content = '发送邮箱验证码'

      })
    },
    async doneStep() {
      this.$refs.emailFormRef.validate(async valid => {
        if (!valid) return
        const {data: res} = await this.$http.post('user/edit_email', {
          email: this.emailForm.email,
          code: this.emailForm.code,
          type: 2
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success('修改成功')
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch("EDIT_USERINFO", res.user)
        this.editEmailVisible = false
        this.stepActive = 0
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.phoneForm,
.emailForm {
  width: 80%;
  margin: 20px auto 0;
}
</style>
